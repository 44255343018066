import React, { useRef, useState , useEffect} from 'react'
import PropTypes from 'prop-types'
import Modal from "../Modal/"

import "./Faculty.css";
import leftArrow from "./images/linearrow.svg";
import rightArrow from "./images/linearrowright.svg";

Faculty.propTypes = {
    staff: PropTypes.array
}

export default function Faculty(props){

    const faculty = useRef();
    const prevSlide = useRef();
    const nextSlide = useRef();
    const slideStrip = useRef();

    let numberSlides = props.staff.length;
    let [currentSlide, setCurrentSlide] = useState(1);
    let [large, setLarge] = useState(false);
    let [showperson, setShowperson] = useState({});
    
    //https://www.pluralsight.com/guides/re-render-react-component-on-window-resize
    const [dimensions, setDimensions] = useState({
        height: 0,
        width: 0
    });

    // if(typeof window !== `undefined`){
    //     setDimensions({
    //         height: window.innerHeight,
    //         width: window.innerWidth
    //     })
    // }
    
    useEffect(function(){
        if(dimensions.width<600)
        {
            moveSlide();
        }
    },[currentSlide]);

    // this one is on initial load
    useEffect(function(){
        if(dimensions.width!=window.innerWidth){
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }
        console.log(dimensions.width)
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }
        //alert(dimensions.width );
        if (dimensions.width < 600){
            setLarge(false)
            slideStrip.current.style.width = (faculty.current.clientWidth * numberSlides) + "px";
        
            setSlideWidth();
            moveSlide();
            window.addEventListener('resize', handleResize);

            return _ => {
                window.removeEventListener('resize', handleResize)
            }
        } else {
            setLarge(true)
        }

    },[dimensions]);

    function setSlideWidth(){
        let slides = faculty.current.querySelectorAll(".slide");
        console.log("setting each slide width:", faculty.current.clientWidth)
        slides.forEach(slide=>{
            slide.style.width = faculty.current.clientWidth + "px";
            slide.querySelector("article").style.width = faculty.current.clientWidth + "px";
        })
    }

    function moveSlide(){
        removeActiveFromAllSlides();
        faculty.current.querySelectorAll(".slide")[currentSlide-1].style.opacity = "1";
        slideStrip.current.style.left = -1*(( currentSlide * faculty.current.clientWidth ) - faculty.current.clientWidth) + "px";

    }

    function removeActiveFromAllSlides(){
        let slides = faculty.current.querySelectorAll(".slide");
        slides.forEach(slide=>{
            slide.style.opacity = "0";
        })
    }

    function goPrevSlide(){
        if(currentSlide>1){
            let newSlideNum = currentSlide - 1;
            setCurrentSlide(newSlideNum);
        }
    }

    function goNextSlide(){
        if(currentSlide<numberSlides){
            let newSlideNum = currentSlide + 1;
            setCurrentSlide(newSlideNum);
        }
    }
   
    if (!large)
    {
        return (
            <div className="faculty" ref={faculty}>
                <div className="mask">
                    <div className="slidestrip" ref={slideStrip}>
                        {
                            props.staff.map((person, i)=>(
                                <div className="slide" key={"peron"+i}>
                                    <article className="content">
                                        <div className="slideImage" style={{backgroundImage: `url("http://api.vanarts.com:3000/assets/${person.image}")`}}>

                                        </div>
                                        <div className="details">
                                            <h3>{person.name}</h3>
                                            <span className="title">{person.longPosition}</span>
                                            
                                            <div dangerouslySetInnerHTML={{__html: person.bio}}></div>

                                            <a href="#" className="cta-secondary">CLick to Learn More About {person.name}</a>
                                        </div>
                                    </article>
                                </div>        
                            ))
                        }
                        
                    </div>
                </div>
                {
                    dimensions.width < 600 &&
                    <>
                    <div className="arrow-red-left arrow" ref={prevSlide} onClick={goPrevSlide}><img src={leftArrow} alt="previous"/></div>
                    <div className="arrow-red-right arrow" ref={nextSlide} onClick={goNextSlide}><img src={rightArrow} alt="next"/></div>
                    </>
                }
                
            </div>
    )
    } else {
        return (
            <>
            <div className="facultygrid">

                {
                    props.staff.map((person, i)=>(
                        <article className="member" key={"peron"+i}>
                            <div onClick={e=>setShowperson(person)} className="member_image" style={{backgroundImage: `url("http://api.vanarts.com:3000/assets/${person.image}")`}}>
                                    <div className="bio-detail">
                                        <div class="container">
                                            <h3>{person.name}</h3>
                                            <span className="title">{person.longPosition}</span>
                                        </div>
                                    </div>
                            </div>   
                        </article>        
                    ))
                }

            </div>
            {
                showperson.name && 
                <Modal>
                    <div className="faculty-modal" onClick={e=>setShowperson({})}>
                        <div className="image" style={{backgroundImage: `url("http://api.vanarts.com:3000/assets/${showperson.image}")`}}>

                        </div>
                        <div className="bio">
                            <h3>{showperson.name}</h3>
                            <span className="title">{showperson.longPosition}</span>
                            
                            <div dangerouslySetInnerHTML={{__html: showperson.bio}}></div>
                        </div>
                        
                    </div>
                </Modal>
            }
            
            </>
        )
    }
}