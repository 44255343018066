// extracted by mini-css-extract-plugin
export const tabGroup = "TabGroup-module--tabGroup--cnv2-";
export const topNav = "TabGroup-module--topNav--2ez8Y";
export const cover = "TabGroup-module--cover--2lIgu";
export const active = "TabGroup-module--active--2yHTu";
export const tabContent = "TabGroup-module--tabContent--2nFNP";
export const tags = "TabGroup-module--tags--28xmc";
export const tab = "TabGroup-module--tab--2PYzU";
export const video = "TabGroup-module--video--3tvZW";
export const videoWrapper = "TabGroup-module--videoWrapper--128Un";
export const maindetails = "TabGroup-module--maindetails--1zt8a";
export const subdetails = "TabGroup-module--subdetails--1X6Jf";