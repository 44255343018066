import React, {useState, useEffect} from 'react'
import * as tabGroupStyle from "./TabGroup.module.css"

export default function TabGroup({data, props}){
    const [tabShowingIndex, setTabShowingIndex] = useState(0);
    const [numTabs, setNumTabs] = useState(0);
    
    useEffect(function(){
        // set the default tab highlighted
        var tabNav = document.querySelector(".tabnav");
        var tabContent = document.querySelector(".tab");
        tabNav.querySelector("button").classList.add(tabGroupStyle.active);
        tabContent.classList.add(tabGroupStyle.active);

        // link the tab nav
        var buttons = tabNav.querySelectorAll("button");
        setNumTabs(buttons.length);

        var contentPanes = document.querySelectorAll(".tab");
        buttons.forEach((button, i)=>{
            button.addEventListener("click", function(){
                resetTabs(buttons);
                resetTabContent(contentPanes);
                button.classList.add(tabGroupStyle.active);

                if(typeof contentPanes[i] !== "undefined"){
                    contentPanes[i].classList.add(tabGroupStyle.active);
                }

                setTabShowingIndex(i)
            })
        })
    }, []);

    useEffect(function(){
        // set the default tab highlighted
        var tabNav = document.querySelector(".tabnav");
        var tabContent = document.querySelector(".tab");
        tabNav.querySelector("button").classList.add(tabGroupStyle.active);
        tabContent.classList.add(tabGroupStyle.active);

        // link the tab nav
        var buttons = tabNav.querySelectorAll("button");
        var contentPanes = document.querySelectorAll(".tab");
       
        resetTabs(buttons);
        resetTabContent(contentPanes);

        var button = tabNav.querySelectorAll("button")[tabShowingIndex];
        var contentPane = document.querySelectorAll(".tab")[tabShowingIndex];

        button.classList.add(tabGroupStyle.active);

        if(typeof contentPane !== "undefined"){
            contentPane.classList.add(tabGroupStyle.active);
        }

    }, [tabShowingIndex]);
    
    function resetTabContent(contentPanes){
        contentPanes.forEach(tab=>{
           tab.classList.remove(tabGroupStyle.active)
        });
    }

    function resetTabs(buttons){
        buttons.forEach(button=>{
            button.classList.remove(tabGroupStyle.active)
        });
    }

    function nextTab(){
        var i = tabShowingIndex + 1;
        if(i>=numTabs){
            i=0;
        }
        setTabShowingIndex(i);
    }
    
    
    return(
     <section className={tabGroupStyle.tabGroup}>
         
         <div className={"tabnav "+tabGroupStyle.topNav}>
             {
                 data.map((tab, i)=>{
                     return(<button key={"tab"+i}>{tab.name}</button>)
                 })
             }
             
         </div>
         <div className={tabGroupStyle.tabs}>
         {
             data.map(tab=>{
                if(tab.skills){
                    var tags = tab.skills.split(',');
                    var skillsTaglist = "";
                    tags.map(tag=> {
                        skillsTaglist += "<div>" + tag + "</div>";
                    });
                }

                if (tab.courses)
                {
                    tags = tab.courses.split(',');
                    var courseTaglist = "";
                    tags.map(tag=> {
                        courseTaglist += "<div>" + tag + "</div>";
                    });
                }
                
                 return (
                <div className={"tab " + tabGroupStyle.tab}>
                    <article>
                        {tab.cover && 
                            // <div className={tabGroupStyle.cover} style={{backgroundImage: "url(http://api.vanarts.com:3000/assets/" + tab.cover+")"}}></div>
                            <div className={tabGroupStyle.cover}>
                                <img src={"http://api.vanarts.com:3000/assets/" + tab.cover} />
                            </div>
                        }
                        {tab.youtube && 
                            <div className={tabGroupStyle.videoWrapper}>
                                <iframe  className="video" src={"http://www.youtube.com/embed/"+tab.youtube+"?rel=0&hd=1"} frameborder="0" allowfullscreen></iframe>
                            </div>
                        }
                        <div className={tabGroupStyle.tabContent}>
                            <div className={tabGroupStyle.maindetails} dangerouslySetInnerHTML={{__html: tab.content}}></div>
                            
                            <div className={tabGroupStyle.subdetails}>
                                {tab.skills && 
                                <div>
                                    <h4>Skills You Will Learn</h4>
                                    <div dangerouslySetInnerHTML={{__html: skillsTaglist}} className={tabGroupStyle.tags}></div>
                                </div>
                                }

                                {tab.courses &&                  
                                <div>          
                                    <h4>Courses in This Term</h4>
                                    <div dangerouslySetInnerHTML={{__html: courseTaglist}} className={tabGroupStyle.tags}></div>
                                </div>
                                }
                            </div>
                        </div>
                    </article>
                </div>
                 )
             })
         }
         
         </div>
         {/* <div className="content">
         <HighlightLink to={window.location.pathname.split("#")[0]+"#curriculum"} label="View Next Term" theme="dark" onClick={e=>nextTab()} />
         </div> */}
         

     </section>
    )
}