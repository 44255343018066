import React, {useState} from "react"
import Modal from "../Modal/"
import "./Gallery.css"


export default function Gallery({entries}){
    
    let [item, setItem] = useState({});

    return (
        <>
        <div className="gallery">
            <div className="gallery-wrapper">
                {
                    entries.map(record=>(
                        <div className="item" onClick={e=>setItem(record)} >
                            <img src={`http://api.vanarts.com:3000/assets/${record.image}`} alt={record.title} />
                        </div>
                    ))
                }

            </div>
        </div>
        {
            item.image &&
            
            <Modal>
                <div className="gallery-modal" onClick={e=>setItem({})}>
                    <div className="image" >
                        <img src={`http://api.vanarts.com:3000/assets/${item.image}`} alt={item.name}/>
                    </div>
                    {/* <div className="bio">
                        <h3>{showperson.name}</h3>
                        <span className="title">{showperson.longPosition}</span>
                        
                        <div dangerouslySetInnerHTML={{__html: showperson.bio}}></div>
                    </div> */}
                    
                </div>
            </Modal>
        }
        </>
    )
}