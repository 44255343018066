import React, {useState, useEffect}from 'react'
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import PrimaryMenu from "../components/PrimaryMenu";
import PageHeadingBox from "../components/PageHeadingBox";
import TabGroup from "../components/TabGroup";
import Faculty from "../components/Faculty";
import ContentSeries from "../components/ContentSeries";
import SectionNav from "../components/SectionNav";
import Gallery from "../components/Gallery";
import Tuition from "../components/Tuition";

const Department = ({pageContext: pagePassedContext}) => {
    
    const [pageContext, setContext] = useState(pagePassedContext);

    let data = pageContext.content.json_data.data;
    let nav = pageContext.flyoutnav;
    let startdateblocks = data.startdateblocks;
    let curriculum = data.curriculum;
    let gallery = data.gallery;
    let tuition = data.tuition;

    let testimonies = {blocks: []};
    data.testimonies.forEach(item=>{
        let mod =  {title: item.title, content: item.description, image: item.image};
        testimonies.blocks.push(mod);
    })

    let staff = data.staff;

    return (
        <Layout nav={nav}>
            <Hero image={data.cover}>
                <PrimaryMenu nav={nav}/>
            </Hero>
            
            <PageHeadingBox boxlabel={data.sectionTitle} title={data.name}>
                <div dangerouslySetInnerHTML={{__html: data.intro}}></div>
            </PageHeadingBox>

            <SectionNav />

            <div className="section-handle row" id="intro">
                <article className="standard-content wide-8_12" id="overview">
                    <div className="pad">
                        <div dangerouslySetInnerHTML={{__html: data.overview}}/>
                        
                        <div className="nextStartDates">
                            {
                                startdateblocks.map(entry=>(
                                    <div className="date">
                                        <span className="day">{entry.day}</span>
                                        <span className="year">{entry.month}</span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </article> 
                <div className="introPhoto wide-4_12">
                    <img src={`http://api.vanarts.com:3000/assets/`+data.introimage}/>
                </div>  
            </div>

            {
                curriculum && 
                
                <article className="standard-content curriculum">
                    <div className="tabintro">
                        <h1 className="sectionHeader">Program Curriculum</h1>
                        <p>The {data.name} curriculum is broken down into 4 terms over the course of 12 months.</p>
                    </div>
                    <TabGroup data={curriculum}/>
                </article> 
            } 

                <article className="standard-content extra-pad">
                    <div className="tabintro">
                        <h1 className="sectionHeader">{gallery.title}</h1>
                        <div dangerouslySetInnerHTML={{__html: gallery.intro}}/>
                    </div>
                    <Gallery entries={gallery.entries}/>
                </article>

                {
                testimonies.blocks[0].title && 
            
                <ContentSeries 
                    title="Hear From Past Grads"
                    content={testimonies} 
                    theme="light"
                />
            }

            {
                staff[0].name && 
                
                <article className="standard-content">
                    <div className="tabintro">
                        <h1 className="sectionHeader">Department Faculty</h1>
                    </div>
                    <Faculty staff={staff} />   
                </article> 
            } 

            <article className="standard-content extra-pad">
                <div className="tabintro">
                    <h1 className="sectionHeader">Tuition and Registration Information</h1>
                </div>
               <Tuition data={tuition}/>
            </article>
        </Layout>
    )
}

export default Department;