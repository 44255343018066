import React from "react"

import "./Modal.css"

export default function Numbers({children}){
    return (
        <div className="modal">
            <div className="modal-content">
                {children}
            </div>
        </div>
    )
}